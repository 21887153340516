import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Divider,
} from '@mui/material';
import { Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';

const AddPaidQuestion = () => {
  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [questionHeader, setQuestionHeader] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [additionalInfoImage, setAdditionalInfoImage] = useState(null);
  const [questions, setQuestions] = useState([
    {
      text: '',
      image: null,
      additional_info: '',
      additional_info_image: null,
      correctAnswer: '',
      answers: [{ text: '', image: null }],
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/addmcq.php?action=fetch_levels');
      const data = await response.json();
      setLevels(data);
    } catch (error) {
      setErrorMessage('Error fetching levels');
    }
  };

  const fetchSubjects = async (levelId) => {
    try {
      const response = await fetch(
        `https://mynewhomeschool.com/student/admin/api/addmcq.php?action=fetch_subjects&level_id=${levelId}`
      );
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      setErrorMessage('Error fetching subjects');
    }
  };

  const handleLevelChange = (e) => {
    const levelId = e.target.value;
    setSelectedLevel(levelId);
    fetchSubjects(levelId);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleQuestionHeaderChange = (e) => {
    setQuestionHeader(e.target.value);
  };

  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
  };

  const handleAdditionalInfoImageChange = (e) => {
    setAdditionalInfoImage(e.target.files[0]);
  };

  const handleQuestionChange = (qIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers[aIndex][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerChange = (qIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].correctAnswer = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: '',
        image: null,
        additional_info: '',
        additional_info_image: null,
        correctAnswer: '',
        answers: [{ text: '', image: null }],
      },
    ]);
  };

  const deleteQuestion = (qIndex) => {
    const updatedQuestions = questions.filter((_, index) => index !== qIndex);
    setQuestions(updatedQuestions);
  };

  const addAnswer = (qIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers.push({ text: '', image: null });
    setQuestions(updatedQuestions);
  };

  const deleteAnswer = (qIndex, aIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].answers = updatedQuestions[qIndex].answers.filter((_, index) => index !== aIndex);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    const formData = new FormData();
    formData.append('question_header', questionHeader);
    formData.append('additional_info', additionalInfo);
    if (additionalInfoImage) formData.append('additional_info_image', additionalInfoImage);
    formData.append('paid_level', selectedLevel);
    formData.append('paid_subject', selectedSubject);

    questions.forEach((question, qIndex) => {
      formData.append(`questions[${qIndex}][text]`, question.text);
      if (question.image) formData.append(`questions[${qIndex}][image]`, question.image);
      formData.append(`questions[${qIndex}][additional_info]`, question.additional_info);
      if (question.additional_info_image) formData.append(`questions[${qIndex}][additional_info_image]`, question.additional_info_image);
      formData.append(`questions[${qIndex}][correct_answer]`, question.correctAnswer);

      question.answers.forEach((answer, aIndex) => {
        formData.append(`questions[${qIndex}][answers][${aIndex}][text]`, answer.text);
        if (answer.image) formData.append(`questions[${qIndex}][answers][${aIndex}][image]`, answer.image);
      });
    });

    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/addmcq.php?action=submit_question', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();

      if (data.success) {
        setSuccessMessage(data.message);
        setQuestionHeader('');
        setAdditionalInfo('');
        setAdditionalInfoImage(null);
        setSelectedLevel('');
        setSelectedSubject('');
        setQuestions([
          {
            text: '',
            image: null,
            additional_info: '',
            additional_info_image: null,
            correctAnswer: '',
            answers: [{ text: '', image: null }],
          },
        ]);
      } else {
        setErrorMessage(data.message || 'Error submitting question');
      }
    } catch (error) {
      setErrorMessage('Error submitting question');
    }

    setLoading(false);
  };

  return (
    <Box sx={{ maxWidth: '90%', margin: '0 auto', padding: 3 }}>
      <Card sx={{ p: 4, boxShadow: 5, borderRadius: '12px' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#333' }}>
          Add Paid Question
        </Typography>

        {successMessage && (
          <Typography color="green" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography color="red" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold' }}>
            {errorMessage}
          </Typography>
        )}

        <form onSubmit={handleSubmit}>
          
{/* 
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Additional Information"
              multiline
              rows={4}
              value={additionalInfo}
              onChange={handleAdditionalInfoChange}
              variant="outlined"
              sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
            />
          </FormControl> */}

          {/* <FormControl fullWidth sx={{ mb: 3 }}>
            <Button variant="contained" component="label" sx={{ bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}>
              Upload Additional Info Image
              <input type="file" hidden onChange={handleAdditionalInfoImageChange} />
            </Button>
            {additionalInfoImage && <Typography variant="body2" sx={{ mt: 1 }}>{additionalInfoImage.name}</Typography>}
          </FormControl> */}

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="paid-level-label">Paid Level</InputLabel>
            <Select
              labelId="paid-level-label"
              value={selectedLevel}
              label="Paid Level"
              onChange={handleLevelChange}
              
              sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
            >
              <MenuItem value="" disabled>
                Choose level
              </MenuItem>
              {levels.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="paid-subject-label">Paid Subject</InputLabel>
            <Select
              labelId="paid-subject-label"
              value={selectedSubject}
              label="Paid Subject"
              onChange={handleSubjectChange}
              
              disabled={!selectedLevel}
              sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
            >
              <MenuItem value="" disabled>
                Choose subject
              </MenuItem>
              {subjects.map((subject) => (
                <MenuItem key={subject.id} value={subject.id}>
                  {subject.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              label="Question Header"
              value={questionHeader}
              onChange={handleQuestionHeaderChange}
              
              variant="outlined"
              sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
            />
          </FormControl>

          <Divider sx={{ mb: 3 }} />

          {questions.map((question, qIndex) => (
            <Card key={qIndex} sx={{ mb: 3, p: 3, boxShadow: 3, borderRadius: '8px', position: 'relative' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                Question {qIndex + 1}
              </Typography>

              {questions.length > 1 && (
                <IconButton
                  color="error"
                  onClick={() => deleteQuestion(qIndex)}
                  sx={{ position: 'absolute', top: 16, right: 16 }}
                >
                  <CloseIcon />
                </IconButton>
              )}

              <FormControl fullWidth sx={{ mb: 3 }}>
                <TextField
                  label="Question Text"
                  value={question.text}
                  onChange={(e) => handleQuestionChange(qIndex, 'text', e.target.value)}
                  
                  variant="outlined"
                  sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <Button variant="contained" component="label" sx={{ bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}>
                  Upload Question Image
                  <input type="file" hidden onChange={(e) => handleQuestionChange(qIndex, 'image', e.target.files[0])} />
                </Button>
                {question.image && <Typography variant="body2" sx={{ mt: 1 }}>{question.image.name}</Typography>}
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <TextField
                  label="Additional Information"
                  multiline
                  rows={3}
                  value={question.additional_info}
                  onChange={(e) => handleQuestionChange(qIndex, 'additional_info', e.target.value)}
                  variant="outlined"
                  sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 3 }}>
                <Button variant="contained" component="label" sx={{ bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}>
                  Upload Additional Info Image
                  <input type="file" hidden onChange={(e) => handleQuestionChange(qIndex, 'additional_info_image', e.target.files[0])} />
                </Button>
                {question.additional_info_image && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {question.additional_info_image.name}
                  </Typography>
                )}
              </FormControl>

              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                Answers
              </Typography>

              <RadioGroup
                value={question.correctAnswer}
                onChange={(e) => handleCorrectAnswerChange(qIndex, e.target.value)}
              >
                {question.answers.map((answer, aIndex) => (
                  <Card key={aIndex} sx={{ mb: 2, p: 2, display: 'flex', alignItems: 'center', boxShadow: 1, borderRadius: '8px', position: 'relative' }}>
                    {question.answers.length > 1 && (
                      <IconButton
                        color="error"
                        onClick={() => deleteAnswer(qIndex, aIndex)}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}

                    <FormControlLabel
                      value={aIndex.toString()}
                      control={<Radio  />}
                      label=""
                      sx={{ mr: 2 }}
                    />

                    <FormControl fullWidth sx={{ mr: 2 }}>
                      <TextField
                        label={`Answer ${aIndex + 1} Text`}
                        value={answer.text}
                        onChange={(e) => handleAnswerChange(qIndex, aIndex, 'text', e.target.value)}
                        
                        variant="outlined"
                        sx={{ bgcolor: '#f9f9f9', borderRadius: '8px' }}
                      />
                    </FormControl>

                    <FormControl>
                      <Button variant="contained" component="label" sx={{ bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}>
                        Upload Image
                        <input type="file" hidden onChange={(e) => handleAnswerChange(qIndex, aIndex, 'image', e.target.files[0])} />
                      </Button>
                      {answer.image && <Typography variant="body2" sx={{ mt: 1 }}>{answer.image.name}</Typography>}
                    </FormControl>
                  </Card>
                ))}
              </RadioGroup>

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => addAnswer(qIndex)}
                sx={{ mt: 2, borderRadius: '8px', color: '#1976d2', borderColor: '#1976d2' }}
              >
                Add Answer
              </Button>
            </Card>
          ))}

          <Button variant="contained" startIcon={<AddIcon />} onClick={addQuestion} sx={{ mb: 3, bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}>
            Add New Question
          </Button>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
            startIcon={loading && <CircularProgress size={20} />}
            sx={{ bgcolor: '#1976d2', '&:hover': { bgcolor: '#1565c0' } }}
          >
            {loading ? 'Submitting...' : 'Submit Questions'}
          </Button>
        </form>
      </Card>
    </Box>
  );
};

export default AddPaidQuestion;
