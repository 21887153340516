import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditAnswerPage = () => {
  const { answerId } = useParams();
  const [formData, setFormData] = useState({ answer_text: '', is_correct: false });
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch answer by ID
    fetch(`https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=fetch_answer&id=${answerId}`)
      .then((res) => res.json())
      .then((data) => {
        setFormData({ answer_text: data.answer_text, is_correct: data.is_correct });
      })
      .catch(() => {
        toast.error("Failed to fetch answer");
      });
  }, [answerId]);

  const handleSubmit = () => {
    // Update the answer
    fetch(`https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=update`, {
      method: 'POST',
      body: JSON.stringify({ ...formData, id: answerId, type: 'answer' }),
      headers: { 'Content-Type': 'application/json' }
    })
    .then(() => {
      toast.success("Answer updated successfully");
      navigate(-1); // Go back to previous page
    })
    .catch(() => {
      toast.error("Failed to update answer");
    });
  };

  return (
    <Box sx={{ p: 2 }}>
      <ToastContainer />
      <Typography variant="h4">Edit Answer</Typography>
      <TextField 
        label="Answer Text" 
        value={formData.answer_text} 
        onChange={(e) => setFormData({ ...formData, answer_text: e.target.value })} 
        fullWidth 
        sx={{ mb: 2 }}
      />
      <TextField
        label="Is Correct"
        type="checkbox"
        checked={formData.is_correct}
        onChange={(e) => setFormData({ ...formData, is_correct: e.target.checked })}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" onClick={handleSubmit}>Save Changes</Button>
    </Box>
  );
};

export default EditAnswerPage;
