import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const AddNewEssay = () => {
  const [data, setData] = useState({
    level_id: '',
    unit_id: '',
    question_header: '',
  });
  const [questions, setQuestions] = useState([{
    question_text: '',
    question_image: null,
    answer_text: '',
    answer_image: null,
    additional_info: '',
    additional_info_image: null,
  }]);
  const [levels, setLevels] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(null); // to track success or failure

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/levels.php?action=fetch_levels');
      const data = await response.json();
      setLevels(data);
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
    setLoading(false);
  };

  const fetchUnitsByLevel = async (levelId) => {
    setLoading(true);
    try {
      const response = await fetch(`https://mynewhomeschool.com/student/admin/api/units.php?action=fetch_units_by_level&level_id=${levelId}`);
      const data = await response.json();
      setUnits(data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });

    if (name === 'level_id') {
      fetchUnitsByLevel(value);
    }
  };

  const handleFileChange = (index, e) => {
    const { name } = e.target;
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = e.target.files[0];
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, e) => {
    const { name, value } = e.target;
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = value;
    setQuestions(updatedQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question_text: '',
        question_image: null,
        answer_text: '',
        answer_image: null,
        additional_info: '',
        additional_info_image: null,
      },
    ]);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    questions.forEach((question, index) => {
      Object.keys(question).forEach((key) => {
        if (question[key]) {
          formData.append(`questions[${index}][${key}]`, question[key]);
        }
      });
    });

    formData.append('action', 'add_essay');

    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/essay.php', {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        setSubmitSuccess(true);
      } else {
        setSubmitSuccess(false);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      setSubmitSuccess(false);
    }
    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSubmitSuccess(null); // Reset snackbar after closing
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Add New Essay Questions
      </Typography>

      {/* Section 1: Level, Subject (Unit), and Question Header */}
      <Box sx={{ mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Level</InputLabel>
          <Select
            name="level_id"
            value={data.level_id}
            onChange={handleInputChange}
            label="Level"
          >
            {levels.map((level) => (
              <MenuItem key={level.id} value={level.id}>
                {level.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Subject (Unit)</InputLabel>
          <Select
            name="unit_id"
            value={data.unit_id}
            onChange={handleInputChange}
            label="Subject"
            disabled={!data.level_id}
          >
            {units.map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Question Header"
          name="question_header"
          value={data.question_header}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
      </Box>

      {/* Section 2: Multiple Questions */}
      {questions.map((question, index) => (
        <Box key={index} sx={{ mb: 3, border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Question {index + 1}
          </Typography>

          <TextField
            label="Question Text"
            name="question_text"
            value={question.question_text}
            onChange={(e) => handleQuestionChange(index, e)}
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          <input
            type="file"
            name="question_image"
            onChange={(e) => handleFileChange(index, e)}
            accept="image/*"
            style={{ marginBottom: '16px' }}
          />

          <TextField
            label="Answer Text"
            name="answer_text"
            value={question.answer_text}
            onChange={(e) => handleQuestionChange(index, e)}
            fullWidth
            multiline
            rows={4}
            sx={{ mb: 2 }}
          />

          <input
            type="file"
            name="answer_image"
            onChange={(e) => handleFileChange(index, e)}
            accept="image/*"
            style={{ marginBottom: '16px' }}
          />

          <TextField
            label="Additional Info"
            name="additional_info"
            value={question.additional_info}
            onChange={(e) => handleQuestionChange(index, e)}
            fullWidth
            sx={{ mb: 2 }}
          />

          <input
            type="file"
            name="additional_info_image"
            onChange={(e) => handleFileChange(index, e)}
            accept="image/*"
            style={{ marginBottom: '16px' }}
          />

          {questions.length > 1 && (
            <IconButton onClick={() => removeQuestion(index)} color="secondary">
              <RemoveIcon />
            </IconButton>
          )}
        </Box>
      ))}

      <Button
        variant="outlined"
        color="primary"
        onClick={addQuestion}
        startIcon={<AddIcon />}
        sx={{ mb: 3 }}
      >
        Add Another Question
      </Button>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>

      {/* Snackbar for success/error message */}
      <Snackbar
        open={submitSuccess !== null}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={submitSuccess ? 'success' : 'error'} sx={{ width: '100%' }}>
          {submitSuccess ? 'Essay added successfully!' : 'Error adding essay. Please try again.'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddNewEssay;
