import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import Dashboard from './components/Dashboard';
import Articles from './components/Articles';
import LibraryManagement from './components/LibraryManagement';
import PaidQuestionsManagement from './components/PaidQuestionsManagement';
import QuestionsPage from './components/QuestionsPage';
import EditAnswerPage from './components/EditAnswerPage';
import EssayQuestionsPage from './components/EssayQuestionsPage';
import StudentManagementPage from './components/StudentManagementPage';
import LessonsManagementPage from './components/LessonsManagementPage';
import ArticlesEventsManagementPage  from './components/ArticlesEventsManagementPage';
import PurchasesManagementPage  from './components/PurchasesManagementPage';
import AdminUserManagementPage  from './components/AdminUserManagementPage';
import { AdUnitsOutlined } from '@mui/icons-material';
import AddPaidQuestion from './components/AddPaidQuestion';
import TeachersManagement from './components/TeachersManagement';
import TopRankedStudentsManagement from './components/TopRankedStudentsManagement';
import LevelsUnitsManagement from './components/LevelsUnitsManagement';
import AddNewEssay from './components/AddNewEssay';


function App() {
  return (
    <Router>
      <Routes>
        {/* Main Login Route */}
        <Route path="/" element={<Login />} />
        
        {/* Admin Panel Routes */}
        <Route path="/admin" element={<AdminPanel />}>
          {/* Default Admin Dashboard */}
          <Route index element={<Dashboard />} />
          
          {/* Library Management */}
          <Route path="lib" element={<LibraryManagement />} />

          {/* Articles Management */}
          <Route path="articles" element={<Articles />} />

          {/* Paid Questions Management */}
          <Route path="onlinep" element={<PaidQuestionsManagement />} />

          {/* Questions page for a specific header */}
          <Route path="onlinep/questions/:header" element={<QuestionsPage />} />
          <Route path="/admin/essay-questions" element={<EssayQuestionsPage />} />
          {/* <Route path="/admin/students" element={<StudentManagementPage />} />
          <Route path="/admin/lession" element={<LessonsManagementPage />} />
          <Route path="/admin/article" element={<ArticlesEventsManagementPage />} />
          <Route path="/admin/purchese" element={<PurchasesManagementPage />} />
          <Route path="/admin/adminuser" element={<AdminUserManagementPage />} /> */}
          <Route path="/admin/addmcq" element={<AddPaidQuestion />} />
          {/* <Route path="/admin/tea" element={<TeachersManagement />} />
          <Route path="/admin/top" element={<TopRankedStudentsManagement />} />
          <Route path="/admin/lev" element={<LevelsUnitsManagement />} /> */}
          <Route path="/admin/addessay" element={<AddNewEssay />} /> */}

          {/* Edit Answer Page (nested under /admin) */}
          <Route path="onlinep/questions/edit-answer/:answerId" element={<EditAnswerPage />} />
        </Route>

        {/* Logout Route */}
        <Route path="/logout" element={<h1>Logging out...</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
