// src/components/Articles.js
import React from 'react';
import { Typography, Box } from '@mui/material';

function Articles() {
  return (
    <Box>
      <Typography variant="h4">Articles</Typography>
      {/* You can add logic to fetch and display articles here */}
      <Typography>All articles will be displayed here.</Typography>
    </Box>
  );
}

export default Articles;
