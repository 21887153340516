import React, { useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText, Typography, CssBaseline, Box, Avatar, Divider, IconButton } from '@mui/material';
import { Dashboard as DashboardIcon, Article as ArticleIcon, LibraryBooks as LibraryIcon, Help as HelpIcon, Logout as LogoutIcon, Event as EventIcon, School as SchoolIcon, QuestionAnswer as QuestionAnswerIcon, Class as ClassIcon, Menu as MenuIcon } from '@mui/icons-material';

const drawerWidth = 240;

// Sidebar Navigation Component
const Sidebar = ({ isMobileOpen, handleDrawerToggle }) => (
  <Drawer
    variant={isMobileOpen ? "temporary" : "permanent"}
    open={isMobileOpen}
    onClose={handleDrawerToggle}
    sx={{
      width: drawerWidth,
      flexShrink: 0,
      [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', bgcolor: 'white', color: '#2c3e50' },
    }}
  >
    <Toolbar sx={{ backgroundColor: '#1abc9c', color: 'white', textAlign: 'center' }}>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        <Avatar sx={{ bgcolor: '#2c3e50', mr: 2 }}>A</Avatar>
        <Typography variant="h6">Admin Panel</Typography>
      </Box>
    </Toolbar>

    <Box sx={{ overflow: 'auto' }}>
      <List>
        {/* <ListItem button component={Link} to="/admin" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <DashboardIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem button component={Link} to="/admin/lib" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <LibraryIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Library Management" />
        </ListItem> */}

        {/* <ListItem button component={Link} to="/admin/onlinep" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <HelpIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="MCQ Questions" />
        </ListItem> */}

        <ListItem button component={Link} to="/admin/addmcq" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <HelpIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Add MCQ Questions" />
        </ListItem>
        <ListItem button component={Link} to="/admin/addessay" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <HelpIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Add Essay Questions" />
        </ListItem>

        {/* <ListItem button component={Link} to="/admin/essay-questions" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <QuestionAnswerIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Essay Questions" />
        </ListItem> */}

        {/* <ListItem button component={Link} to="/admin/students" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <SchoolIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Students Management" />
        </ListItem>
        <ListItem button component={Link} to="/admin/top" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <SchoolIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Top Students Management" />
        </ListItem>

        <ListItem button component={Link} to="/admin/purchese" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <SchoolIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Purchese Management" />
        </ListItem>

        <ListItem button component={Link} to="https://mynewhomeschool.com/student/admin/doubt_mng.php" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <HelpIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Doubt Management" />
        </ListItem>

        <ListItem button component={Link} to="/admin/lession" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <ClassIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Lesson Management" />
        </ListItem>
        <ListItem button component={Link} to="/admin/lev" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <ClassIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Levels & Subjects Management" />
        </ListItem>

        <ListItem button component={Link} to="/admin/article" sx={{ '&:hover': { bgcolor: '#ecf0f1' } }}>
          <ListItemIcon>
            <EventIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Events & Articles" />
        </ListItem>

       
        <ListItem button component={Link} to="/admin/adminuser" sx={{ '&:hover': { bgcolor: '#e74c3c' } }}>
          <ListItemIcon>
            <LogoutIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Admin Users" />
        </ListItem>
        <ListItem button component={Link} to="/admin/tea" sx={{ '&:hover': { bgcolor: '#e74c3c' } }}>
          <ListItemIcon>
            <LogoutIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Teachers" />
        </ListItem> */}
        <Divider sx={{ my: 1 }} />
        <ListItem button component={Link} to="/logout" sx={{ '&:hover': { bgcolor: '#e74c3c' } }}>
          <ListItemIcon>
            <LogoutIcon sx={{ color: '#1abc9c' }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  </Drawer>
);

// Admin Panel Layout Component
const AdminPanel = () => {
  const [isMobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!isMobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />

      {/* AppBar for the top bar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#1abc9c',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}  // Only show the menu button on small screens
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Panel
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Sidebar isMobileOpen={isMobileOpen} handleDrawerToggle={handleDrawerToggle} />

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // bgcolor: '#ecf0f1',
          p: 3,
          minHeight: '100vh',
          marginTop: '64px',
        }}
      >
        {/* Outlet will render the nested routes content */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminPanel;
