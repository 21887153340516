import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, List, ListItem, ListItemText, Button, Card, CardContent, CardMedia,
  IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Divider, Pagination
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Search as SearchIcon } from '@mui/icons-material';

// Book Card Component
const BookCard = ({ book, onEdit, onDelete }) => (
  <Card sx={{ display: 'flex', mb: 2 }}>
    <CardMedia
      component="img"
      sx={{ width: 150 }}
      image={`https://mynewhomeschool.com/student/admin/${book.thumbnail_url}`}
      alt={book.title}
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <CardContent>
        <Typography variant="h5">{book.title}</Typography>
        <Typography variant="subtitle1">{book.media_type}</Typography>
        <Typography variant="body2">{book.des}</Typography>
      </CardContent>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: 2 }}>
      <IconButton onClick={() => onEdit(book)} color="primary">
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onDelete(book.id)} color="secondary">
        <DeleteIcon />
      </IconButton>
    </Box>
  </Card>
);

// Add/Edit Book Modal Component
const BookModal = ({ open, onClose, onSubmit, book }) => {
  const [formData, setFormData] = useState({
    title: book?.title || '',
    media_type: book?.media_type || '',
    media_url: book?.media_url || '',
    thumbnail_url: book?.thumbnail_url || '',
    des: book?.des || ''
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({
        ...formData,
        [name]: URL.createObjectURL(files[0]),
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  useEffect(() => {
    setFormData({
      title: book?.title || '',
      media_type: book?.media_type || '',
      media_url: book?.media_url || '',
      thumbnail_url: book?.thumbnail_url || '',
      des: book?.des || ''
    });
  }, [book]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{book ? 'Edit Book' : 'Add Book'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
        />

        {/* Media Type Dropdown */}
        <TextField
          select
          margin="dense"
          label="Media Type"
          name="media_type"
          value={formData.media_type}
          onChange={handleChange}
          fullWidth
          SelectProps={{
            native: true,
          }}
        >
          <option value="">Select Media Type</option>
          <option value="PDF">PDF</option>
          <option value="Audio">Audio</option>
          <option value="Video">Video</option>
          <option value="Image">Image</option>
        </TextField>

        {/* Media URL Upload */}
        <label htmlFor="media_url">Upload Media URL</label>
        <input
          type="file"
          name="media_url"
          accept=".pdf,.mp3,.mp4,.jpg,.jpeg,.png"
          onChange={handleFileChange}
          style={{ marginTop: '15px' }}
        />
        {formData.media_url && (
          <Typography variant="body2">Media Preview: {formData.media_url}</Typography>
        )}

        {/* Thumbnail URL Upload */}
        <label htmlFor="thumbnail_url">Upload Thumbnail</label>
        <input
          type="file"
          name="thumbnail_url"
          accept=".jpg,.jpeg,.png"
          onChange={handleFileChange}
          style={{ marginTop: '15px' }}
        />
        {formData.thumbnail_url && (
          <CardMedia
            component="img"
            sx={{ width: 100, marginTop: '15px' }}
            image={formData.thumbnail_url}
            alt="Thumbnail Preview"
          />
        )}

        <TextField
          margin="dense"
          label="Description"
          name="des"
          value={formData.des}
          onChange={handleChange}
          multiline
          rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{book ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

// Add Category Modal Component
const AddCategoryModal = ({ open, onClose, onSubmit, parentCategory }) => {
  const [categoryName, setCategoryName] = useState('');

  const handleSubmit = () => {
    onSubmit(categoryName);
    setCategoryName('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Add Subcategory to "${parentCategory?.name || 'Root'}"`}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Category Name"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Add</Button>
      </DialogActions>
    </Dialog>
  );
};

// Main Library Management Component
const LibraryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [currentCategoryPath, setCurrentCategoryPath] = useState([]); // Tracks the step-by-step path of selected categories
  const [books, setBooks] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [bookModalOpen, setBookModalOpen] = useState(false);
  const [bookToEdit, setBookToEdit] = useState(null);
  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const booksPerPage = 5;

  const currentCategoryId = currentCategoryPath[currentCategoryPath.length - 1]?.id || null;

  // Fetch categories
  useEffect(() => {
    fetch('https://mynewhomeschool.com/student/admin/api/library_management.php?action=fetch_categories')
      .then((res) => res.json())
      .then((data) => setCategories(data.categories));
  }, []);

  // Fetch books for the selected category
  useEffect(() => {
    if (currentCategoryId) {
      fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=fetch_books&category_id=${currentCategoryId}`)
        .then((res) => res.json())
        .then((data) => {
          setBooks(data.books);
          setFilteredBooks(data.books);
        });
    } else {
      setBooks([]);
    }
  }, [currentCategoryId]);

  // Filter books based on search term
  useEffect(() => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    setFilteredBooks(
      books.filter((book) =>
        book.title.toLowerCase().includes(lowerCaseTerm)
      )
    );
  }, [searchTerm, books]);

  const getSubcategories = (parentId) => {
    return categories.filter(category => category.parent_id === parentId);
  };

  const handleCategoryClick = (category) => {
    setCurrentCategoryPath([...currentCategoryPath, category]);
  };

  const handleBackCategoryClick = (index) => {
    setCurrentCategoryPath(currentCategoryPath.slice(0, index + 1));  // Go back to a specific category in the path
  };

  const handleAddBook = () => {
    setBookToEdit(null);  // Reset to add mode
    setBookModalOpen(true);
  };

  const handleEditBook = (book) => {
    setBookToEdit(book);  // Set book to edit
    setBookModalOpen(true);
  };

  const handleDeleteBook = (bookId) => {
    fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=delete_book`, {
      method: 'POST',
      body: new URLSearchParams({ book_id: bookId }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          setBooks((prevBooks) => prevBooks.filter((book) => book.id !== bookId));
        }
      });
  };

  const handleBookSubmit = (formData) => {
    const action = bookToEdit ? 'edit_book' : 'add_book';
    const bookId = bookToEdit ? bookToEdit.id : null;

    const params = new URLSearchParams({
      ...formData,
      category_id: currentCategoryId,
      book_id: bookId
    });

    fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=${action}`, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          setBookModalOpen(false);
          setBooks((prevBooks) => {
            if (bookToEdit) {
              return prevBooks.map((b) => (b.id === bookId ? { ...b, ...formData } : b));
            } else {
              return [...prevBooks, { ...formData, id: data.book_id }];
            }
          });
        }
      });
  };

  const handleAddCategory = () => {
    setAddCategoryModalOpen(true);
  };

  const handleAddCategorySubmit = (categoryName) => {
    const parentCategoryId = currentCategoryId || null;
    const params = new URLSearchParams({
      name: categoryName,
      parent_id: parentCategoryId
    });

    fetch(`https://mynewhomeschool.com/student/admin/api/library_management.php?action=add_category`, {
      method: 'POST',
      body: params,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          setCategories([...categories, data.category]);
          setAddCategoryModalOpen(false);
        }
      });
  };

  const resetToTopCategory = () => {
    setCurrentCategoryPath([]); // Reset category path
  };

  // Pagination
  const startIndex = (currentPage - 1) * booksPerPage;
  const paginatedBooks = filteredBooks.slice(startIndex, startIndex + booksPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Category Navigation */}
      <Box sx={{ width: '25%', p: 2, bgcolor: '#f4f4f4' }}>
        <Typography variant="h6" gutterBottom>Categories</Typography>

        {/* Reset Category Path */}
        <Button variant="outlined" color="primary" onClick={resetToTopCategory}>
          Back to Top Category
        </Button>

        {/* Current Category Path (Breadcrumb) */}
        <Box>
          {currentCategoryPath.map((category, index) => (
            <span key={category.id}>
              <Button color="primary" onClick={() => handleBackCategoryClick(index)}>
                {category.name}
              </Button>
              {index < currentCategoryPath.length - 1 && ' > '}
            </span>
          ))}
        </Box>

        <Divider sx={{ my: 2 }} />

        {/* Subcategories */}
        <List>
          {getSubcategories(currentCategoryId).map((subcategory) => (
            <ListItem button key={subcategory.id} onClick={() => handleCategoryClick(subcategory)}>
              <ListItemText primary={subcategory.name} />
            </ListItem>
          ))}
        </List>

        {/* Add Category Button */}
        <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddCategory}>
          Add Subcategory
        </Button>
      </Box>

      {/* Books Display */}
      <Box sx={{ width: '75%', p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6">Content</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Search by Title"
              fullWidth
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: <SearchIcon />
              }}
            />
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddBook}>
              Add Content
            </Button>
          </Grid>
        </Grid>

        {/* Book List */}
        <Box mt={2}>
          {paginatedBooks.length > 0 ? (
            paginatedBooks.map((book) => (
              <BookCard
                key={book.id}
                book={book}
                onEdit={handleEditBook}
                onDelete={handleDeleteBook}
              />
            ))
          ) : (
            <Typography>No books/Content available</Typography>
          )}
        </Box>

        {/* Pagination */}
        {filteredBooks.length > booksPerPage && (
          <Pagination
            count={Math.ceil(filteredBooks.length / booksPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
          />
        )}
      </Box>

      {/* Add/Edit Book Modal */}
      <BookModal
        open={bookModalOpen}
        onClose={() => setBookModalOpen(false)}
        onSubmit={handleBookSubmit}
        book={bookToEdit}
      />

      {/* Add Category Modal */}
      <AddCategoryModal
        open={addCategoryModalOpen}
        onClose={() => setAddCategoryModalOpen(false)}
        onSubmit={handleAddCategorySubmit}
        parentCategory={currentCategoryPath[currentCategoryPath.length - 1] || null}
      />
    </Box>
  );
};

export default LibraryManagement;
